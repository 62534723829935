@font-face {
  font-family: "Magistral";
  src: url("./fonts/MAGISTRAL-NORMAL-FIN.woff") format("woff");
}

#root {
  background: linear-gradient(to left, #0a0f11b0, #0a0f11a6),
    url(https://i.ibb.co/d5Q4LWg/background.jpg) no-repeat;
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
}

.wip-bg {
  background: rgb(9, 3, 15);
  background: linear-gradient(
    90deg,
    rgba(9, 3, 15, 1) 0%,
    rgba(7, 28, 28, 1) 100%
  );
}

.App {
  overflow: hidden;
}

.App .Profile {
  position: relative;
  z-index: 1;
}

.white {
  color: #fff;
}

.hometab {
  padding-top: 35px;
  width: 40%;
  margin: 0 auto; /* Centers the div horizontally */
  text-align: center; /* Centers the content inside the div */
  padding-bottom: 1rem;
}

.homelogo {
  width: 50%;
  margin: 0 auto; /* Centers the logo horizontally */
  display: block;
}

.hometext {
  font-size: 16px;
  color: white;
  margin-top: 1rem;
}

.badge-text {
  position: relative;
  width: 80%;
  transform: translate(0%, -100%);
}
