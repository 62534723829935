/* common css */
section {
  font-family: "Magistral", sans-serif;
}

.text-justify {
  text-align: justify;
}

.word-break {
  word-break: break-word;
}

h5 {
  font-size: 16px !important;
}

h4 {
  font-size: 20px !important;
}

h3 {
  font-size: 22px !important;
}

h2 {
  font-size: 24px !important;
}

h1 {
  font-size: 30px !important;
}

p {
  font-size: 16px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-10 {
  font-size: 10px;
}

.w-30 {
  width: 30%;
}

.max-w-85 {
  max-width: 85%;
}

.w-xxl-7 {
  width: 30%;
}

.w-xxl-6 {
  width: 40%;
}

.w-jobfunction {
  width: 120px;
  height: 120px;
}

.max-w-100 {
  max-width: 100%;
}

.bg-grey-gradient {
  background: linear-gradient(
    205deg,
    rgba(217, 217, 217, 0.1) 0%,
    rgba(115, 115, 115, 0.39) 100%
  );
}

.bg-black-gradient {
  background: linear-gradient(
    225deg,
    rgb(64 60 60 / 30%) 0%,
    rgb(32 24 24 / 80%) 100%
  );
}

.pe-7 {
  padding-right: 80px;
}

.progress {
  width: 80%;
  height: 5px;
}

.word-break {
  word-break: break-word;
}

.green {
  color: #9cfc00;
}

.blue {
  color: #19f2fa;
}

.blue-green {
  color: #02b372;
}

.pink {
  color: #ff529d;
}

.ls-8 {
  letter-spacing: 8px;
}

h5 {
  font-size: 1.2rem;
}

.lh-20 {
  line-height: 22px;
}

.bg-green {
  background-color: #142114;
}

.shadow-green {
  box-shadow: 0 0 40px 20px #012609;
}

.shadow-light-green {
  box-shadow: 0 0 40px 15px #011906;
}

.shadow-violet {
  box-shadow: 0 4px 15px -4px #b075ff9e;
}

.w-85 {
  width: 55%;
}

.w-55 {
  width: 55%;
}

.jobfunction-traitsheading {
  max-width: 70%;
}

.divider {
  width: 2px;
  height: 100px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    #ffffff,
    rgba(0, 0, 0, 0)
  );
  margin: 40px 5px;
}
.col-6:not(:last-child) .divider {
  display: block;
}

.divider2 {
  width: 2px;
  height: 100px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    #ffffff,
    rgba(0, 0, 0, 0)
  );
  margin: 60px 5px;
}
.col-6:not(:last-child) .divider2 {
  display: block;
}

.img-fluid {
  width: 30%;
}

.mb-xxl-6 {
  margin-bottom: 6rem !important;
}

.flare {
  position: absolute;
  bottom: -1px;
  width: 50px;
  height: 2px;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    #ffffff,
    rgba(0, 0, 0, 0)
  );
  border-radius: 50%;
  animation: flare-animation 30s ease-in-out infinite;
  z-index: 1;
}
@keyframes flare-animation {
  0% {
    left: 0%;
    opacity: 1;
  }
  16.66% {
    /* 5 seconds of 70 seconds is approximately 7.14% */
    left: 93%;
    opacity: 1;
  }
  16.67% {
    opacity: 0; /* Disappear */
  }
  100% {
    opacity: 0; /* Remain hidden */
  }
}

.flare2 {
  position: absolute;
  bottom: -1px;
  width: 50px;
  height: 2px;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    #ffffff,
    rgba(0, 0, 0, 0)
  );
  border-radius: 50%;
  animation: flare-animation2 30s ease-in-out infinite;
  z-index: 1;
}
@keyframes flare-animation2 {
  0% {
    right: 0%;
    opacity: 1;
  }
  16.66% {
    /* 5 seconds of 70 seconds is approximately 7.14% */
    right: 93%;
    opacity: 1;
  }
  16.67% {
    opacity: 0; /* Disappear */
  }
  100% {
    opacity: 0; /* Remain hidden */
  }
}
.flare3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 2px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 5px 1px rgba(255, 255, 255);
  border-radius: 50%;
  animation: flare-animation3 70s ease-in-out infinite;
  z-index: 1;
}

@keyframes flare-animation3 {
  0% {
    left: 0%;
    opacity: 1;
  }
  7.14% {
    /* 5 seconds of 70 seconds is approximately 7.14% */
    left: 100%;
    opacity: 1;
  }
  7.15% {
    opacity: 0; /* Disappear */
  }
  100% {
    opacity: 0; /* Remain hidden */
  }
}

.flare4 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 3px;
  height: 2px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 5px 1px rgba(255, 255, 255);
  border-radius: 50%;
  animation: flare-animation4 70s ease-in-out infinite;
  z-index: 1;
}

@keyframes flare-animation4 {
  0% {
    right: 0%;
    opacity: 1;
  }
  7.14% {
    /* 5 seconds of 70 seconds is approximately 7.14% */
    right: 100%;
    opacity: 1;
  }
  7.15% {
    opacity: 0; /* Disappear */
  }
  100% {
    opacity: 0; /* Remain hidden */
  }
}

.flare5 {
  position: absolute;
  bottom: -2px;
  width: 3px;
  height: 2px;
  background: rgb(255, 255, 255, 0.7);
  box-shadow: 0 0 5px 1px rgb(255, 255, 255);
  border-radius: 50%;
  animation: flare-animation5 70s ease-in-out infinite;
  z-index: 1;
  margin-left: 35px;
}
@keyframes flare-animation5 {
  0% {
    left: 0%;
    opacity: 1;
  }
  7.14% {
    /* 5 seconds of 70 seconds is approximately 7.14% */
    left: 72%;
    opacity: 1;
  }
  7.15% {
    opacity: 0; /* Disappear */
  }
  100% {
    opacity: 0; /* Remain hidden */
  }
}

.flare6 {
  position: absolute;
  bottom: 0;
  width: 3px;
  height: 2px;
  background: rgb(255, 255, 255, 0.5);
  box-shadow: 0 0 5px 1px rgb(255, 255, 255);
  border-radius: 50%;
  animation: flare-animation6 70s ease-in-out infinite;
  z-index: 1;
  margin-right: 35px;
}
@keyframes flare-animation6 {
  0% {
    right: 0%;
    opacity: 1;
  }
  7.14% {
    /* 5 seconds of 70 seconds is approximately 7.14% */
    right: 72%;
    opacity: 1;
  }
  7.15% {
    opacity: 0; /* Disappear */
  }
  100% {
    opacity: 0; /* Remain hidden */
  }
}

.soft-skills-wrapper {
  position: relative;
  width: 85%;
  z-index: 1;
}

.soft-skills-wrapper1 {
  position: relative;
  left: 100px;
  width: 85%;
  z-index: 1;
}

.soft-skills {
  position: relative;
  width: 100%;
  padding: 15px 80px 15px 15px;
  z-index: 1;
}

.neuroda {
  position: absolute;
  width: 20%;
  right: 90px;
  z-index: 2;
}

.soft-skills1 {
  position: relative;
  width: 100%;
  padding: 15px 15px 15px 80px;
  z-index: 1;
}

.neuroda1 {
  position: absolute;
  width: 19%;
  transform: scale(-1, 1);
  z-index: 2;
}

.CircularProgressbar {
  overflow: inherit;
  width: 60%;
  margin-bottom: 10px;
}

.skill {
  width: 90%;
}

/* .skill .CircularProgressbar-path {
  filter: drop-shadow(0 0 5px currentColor) !important;
} */

.circular-progress-capable .CircularProgressbar-path {
  filter: drop-shadow(0 0 5px #19f2fa);
}

.circular-progress-impressive .CircularProgressbar-path {
  filter: drop-shadow(0 0 5px #02b372);
}

.circular-progress-exceptional .CircularProgressbar-path {
  filter: drop-shadow(0 0 5px #ff529d);
}

.custom-loader h6 {
  padding: 55px 25px;
  word-wrap: break-word;
}

.custom-loader-wrapper {
  text-align: center;
}

.custom-loader-wrapper p {
  max-width: 130px;
  margin: 0 auto;
  word-wrap: break-word;
}

@media (3301px <= width <=4000px) {
  .p-xxl-8 {
    padding: 5rem !important;
  }
  .p-xxl-6 {
    padding: 8rem !important;
  }
  .fs-1 {
    font-size: 6rem !important;
  }

  .divider {
    width: 4px;
    height: 300px;
    margin: 150px 5px;
  }
  .divider2 {
    width: 4px;
    height: 300px;
    margin: 150px 5px;
  }

  .w-xxl-7 {
    width: 18%;
  }

  .w-xxl-6 {
    width: 35%;
  }

  h1 {
    font-size: 5rem;
  }

  h5 {
    font-size: 4rem;
  }

  .p-xxl-7 {
    padding: 5.5rem !important;
  }
  .CircularProgressbar {
    width: 100%;
  }

  .mt-xxl-6 {
    margin-top: 6rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 8rem !important;
  }

  .skill {
    width: 90%;
  }

  .custom-loader-wrapper p {
    max-width: 350px;
    font-size: 50px;
  }
  .w-55,
  .w-85 {
    width: 50%;
  }
  .px-xxl-6 {
    padding: 0 6rem !important;
  }
  .soft-skills {
    padding: 45px 300px 45px 45px;
  }
  .soft-skills-wrapper {
    width: 86%;
  }
  .neuroda {
    width: 21%;
    right: 160px;
  }
  .soft-skills1 {
    padding: 45px 45px 45px 200px;
  }
  .soft-skills-wrapper1 {
    left: 480px;
  }
}

@media (2701px <= width <=3300px) {
  .p-xxl-8 {
    padding: 4rem !important;
  }
  .soft-skills1 {
    padding: 45px 45px 45px 200px;
  }
  .soft-skills {
    padding: 45px 300px 45px 45px;
  }
  .px-xxl-6 {
    padding: 0 6rem !important;
  }

  .custom-loader-wrapper p {
    max-width: 300px;
    font-size: 40px;
  }

  .skill {
    width: 90%;
  }

  .p-xxl-6 {
    padding: 6rem !important;
  }

  .fs-1 {
    font-size: 6rem !important;
  }

  .divider {
    width: 4px;
    height: 250px;
    margin: 80px 5px;
  }

  .divider2 {
    width: 6px;
    height: 300px;
    margin: 80px 5px;
  }

  .CircularProgressbar {
    width: 100%;
  }

  .w-xxl-7 {
    width: 20%;
  }

  .w-xxl-6 {
    width: 30%;
  }

  h1 {
    font-size: 5rem;
  }

  h5 {
    font-size: 4rem;
  }

  .lh-20 {
    line-height: 60px;
  }

  .p-xxl-7 {
    padding: 3.5rem !important;
  }

  .mt-xxl-6 {
    margin-top: 6rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 6rem !important;
  }

  .soft-skills-wrapper1 {
    left: 300px;
  }
  .soft-skills1 {
    padding: 30px 30px 30px 200px;
  }
  .neuroda {
    right: 150px;
  }
}

@media (1800px <= width <=2700px) {
  .custom-loader-wrapper p {
    max-width: 300px;
    font-size: 40px;
  }
  .skill {
    width: 90%;
  }
  .mt-xxl-6 {
    margin-top: 6rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 6rem !important;
  }
  .p-xxl-6 {
    padding: 5rem !important;
  }
  .fs-1 {
    font-size: 5rem !important;
  }
  .divider {
    width: 4px;
    height: 200px;
  }
  .divider2 {
    width: 4px;
    height: 200px;
    margin: 180px 5px;
  }
  .w-xxl-7 {
    width: 30%;
  }
  .w-xxl-6 {
    width: 40%;
  }
  h1 {
    font-size: 4.5rem;
  }
  h5 {
    font-size: 3.5rem;
  }
  .lh-20 {
    line-height: 50px;
  }
  .soft-skills {
    padding: 30px 200px 30px 30px;
  }
  .px-xxl-6 {
    padding: 0 6rem !important;
  }
  .CircularProgressbar {
    width: 90%;
  }
  .p-xxl-7 {
    padding: 2.5rem !important;
  }
  .soft-skills-wrapper1 {
    left: 300px;
  }
  .soft-skills1 {
    padding: 30px 30px 30px 200px;
  }
  .neuroda {
    right: 150px;
  }
}

@media (1400px <= width <=1800px) {
  .soft-skills-wrapper {
    width: 90%;
  }

  .neuroda {
    width: 14%;
    right: 120px;
  }

  .soft-skills-wrapper1 {
    width: 90%;
    left: 80px;
  }
  .neuroda1 {
    width: 14%;
  }
}

@media (769px <= width <= 1200px) {
  .jobfunction-traitsheading {
    max-width: 60%;
  }
  .w-xxl-7 {
    width: 40%;
  }

  h5 {
    font-size: 1.2rem;
  }

  .mt-xlg-1 {
    margin-top: 0.5rem !important;
  }

  .px-lg-5 {
    padding: 0 !important;
  }

  .w-85 {
    width: 100%;
  }

  .fs-12 {
    font-size: 12px;
  }

  .w-55 {
    width: 65%;
  }

  .soft-skills-wrapper1 {
    left: 70px;
  }

  .neuroda {
    right: 85px;
  }

  .padding-left {
    padding: 15px 0px 15px 40px !important;
  }

  .fs-1 {
    font-size: 2rem !important;
  }
  .skill {
    width: 100%;
  }
  .pe-7 {
    padding-right: 80px;
  }
}

@media screen and (min-width: 450px) and (max-width: 768px) {
  .w-xxl-7 {
    width: 45%;
    margin: auto;
  }

  .pe-7 {
    padding-right: 65px;
  }

  .w-xxl-6 {
    width: 40%;
  }

  h5 {
    font-size: 1.2rem;
  }

  .skill {
    width: 100%;
  }

  .w-md-50 {
    width: 50%;
  }
  .neuroda {
    right: 65px;
  }

  .w-85 {
    width: 135%;
  }

  .w-55 {
    width: 50%;
  }

  .soft-skills {
    padding: 15px 70px 15px 15px;
  }

  .soft-skills-wrapper1 {
    left: 80px;
  }

  .soft-skills1 {
    padding: 15px 15px 15px 70px;
  }

  .custom-loader {
    width: 100px;
    height: 100px;
  }

  .custom-loader h6 {
    padding: 40px 10px;
  }

  .fs-1 {
    font-size: 2rem !important;
  }

  @keyframes flare-animation {
    0% {
      left: 0%;
      opacity: 1;
    }
    16.66% {
      /* 5 seconds of 70 seconds is approximately 7.14% */
      left: 85%;
      opacity: 1;
    }
    16.67% {
      opacity: 0; /* Disappear */
    }
    100% {
      opacity: 0; /* Remain hidden */
    }
  }

  @keyframes flare-animation2 {
    0% {
      right: 0%;
      opacity: 1;
    }
    16.66% {
      /* 5 seconds of 70 seconds is approximately 7.14% */
      right: 85%;
      opacity: 1;
    }
    16.67% {
      opacity: 0; /* Disappear */
    }
    100% {
      opacity: 0; /* Remain hidden */
    }
  }
}

@media screen and (max-width: 450px) {
  .jobfunction-traitsheading {
    max-width: 100%;
  }

  .homelogo {
    width: 100%;
  }

  .w-30 {
    width: 80%;
  }

  .w-xxl-7 {
    width: 60%;
    margin: auto;
  }

  .pe-7 {
    padding-right: 40px;
  }

  h5 {
    font-size: 1.2rem;
  }

  .w-85,
  .w-55 {
    width: 60%;
  }

  .fs-1 {
    font-size: 1.5rem !important;
  }

  .ls-8 {
    letter-spacing: 2px;
  }
  .neuroda {
    right: 20px;
  }
  .soft-skills {
    padding: 15px 15px 15px 15px;
  }
  .soft-skills-wrapper {
    width: 100%;
  }

  .soft-skills-wrapper1 {
    width: 100%;
    left: 0px;
  }

  .w-xxl-6 {
    width: 100%;
  }

  .fs-sm-20 {
    font-size: 20px;
  }
  .soft-skills1 {
    padding: 15px 15px 15px 15px;
  }
  .img-fluid {
    width: 55%;
  }
  .CircularProgressbar {
    width: 50%;
  }

  .skill {
    width: 100%;
  }

  @keyframes flare-animation {
    0% {
      left: 0%;
      opacity: 1;
    }
    16.66% {
      /* 5 seconds of 70 seconds is approximately 7.14% */
      left: 80%;
      opacity: 1;
    }
    16.67% {
      opacity: 0; /* Disappear */
    }
    100% {
      opacity: 0; /* Remain hidden */
    }
  }

  @keyframes flare-animation2 {
    0% {
      right: 0%;
      opacity: 1;
    }
    16.66% {
      right: 80%;
      opacity: 1;
    }
    16.67% {
      opacity: 0; /* Disappear */
    }
    100% {
      opacity: 0; /* Remain hidden */
    }
  }
}
